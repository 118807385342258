import Image from 'next/image';
import { PagesUrls } from '@/app/truco/utils/pageUrls';

export const BannerGooglePlay = () => {
  return (
    <a
      href={PagesUrls.TRUCO_APP}
      title="Jugá a El Gran Truco Argentino en tu Celular"
      className="hidden md:block"
    >
      <div className="relative font-normal" id="google-play-banner">
        <Image
          src="/img/newdesign/v2/home/app-banner-art.png"
          alt="Jugá Truco Argentino en tu Celular"
          width={120}
          height={126}
          className="absolute bottom-0 left-0"
        />
        <h3 className="rounded-t-md bg-primary py-2 pr-2 pl-28 text-white text-xl">
          ¿Querés jugar a El Gran Truco en tu celular o tablet?
        </h3>
        <h3 className="rounded-b-md bg-primary-extralight py-2 pr-2 pl-28 text-primary-dark">
          Bajá nuestra app en
          <Image
            src="/img/newdesign/v2/home/icon-google-play.png"
            alt="Bajate nuestra app en Google Play y juega donde quieras"
            width={146}
            height={32}
            className="-mt-1 mx-2 inline"
          />
          <span> y juega donde quieras</span>
        </h3>
      </div>
    </a>
  );
};
