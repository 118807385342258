import classNames from 'classnames';
import React from 'react';

export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
}

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SECONDARY_INVERSE = 'secondary_inverse',
  PRIMARY_INVERSE = 'primary_inverse',
  DANGER = 'danger',
  TERTIARY = 'tertiary',
}

export interface IButton {
  type?: ButtonType;
  size?: ButtonSize;
  variant?: ButtonVariant;
  label?: string;
  icon?: JSX.Element;
  reversed?: true | false;
  isLoading?: true | false;
  disabled?: true | false;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  style?: React.CSSProperties;
}

export const Button: React.FunctionComponent<IButton> = ({
  type = ButtonType.BUTTON,
  size = ButtonSize.MEDIUM,
  variant = ButtonVariant.PRIMARY,
  label,
  icon,
  reversed,
  disabled,
  isLoading,
  onClick,
  className,
  style,
}: IButton) => {
  const buttonClasses = classNames(
    'flex hover:transition gap-1 items-center justify-center font-bold rounded-md',
    className,
    {
      'flex-row-reverse': reversed,
      'bg-primary text-primary-on focus:outline-primary':
        !disabled && variant === ButtonVariant.PRIMARY,
      'bg-secondary text-secondary-on focus:outline-secondary':
        !disabled && variant === ButtonVariant.SECONDARY,
      'bg-secondary-on text-secondary-container-on focus:outline-secondary-on':
        !disabled && variant === ButtonVariant.SECONDARY_INVERSE,
      'bg-primary-on text-primary-container-on focus:outline-primary-on':
        !disabled && variant === ButtonVariant.PRIMARY_INVERSE,
      'bg-error text-error-on focus:outline-error':
        !disabled && variant === ButtonVariant.DANGER,
      'bg-surface-container-low text-surface-container-on focus:outline-primary':
        !disabled && variant === ButtonVariant.TERTIARY,
      'active:outline-0': !isLoading && !disabled && variant,
      'md:hover:brightness-110 md:active:brightness-110':
        !isLoading && !disabled,
      'cursor-progress': isLoading,
      'cursor-not-allowed text-gray-400': disabled,
      'focus:outline focus:outline-2 focus:outline-offset-2': !disabled,
      'brightness-60': disabled,
      'py-1 text-sm': size === ButtonSize.SMALL,
      'py-2.5 text-base ': size === ButtonSize.MEDIUM,
      'px-6 py-3 text-xl ': size === ButtonSize.LARGE,
      'px-2': size === ButtonSize.SMALL && (!icon || (icon && label)),
      'px-1': size === ButtonSize.SMALL && !label && icon,
      'px-3.5': size === ButtonSize.MEDIUM && (!icon || (icon && label)),
      'px-2.5': size === ButtonSize.MEDIUM && !label && icon,
    },
  );

  const spanClasses = classNames('transition-opacity', {
    'opacity-100': !isLoading,
    'opacity-0': isLoading,
  });

  const loadingClasses = classNames(
    'flex justify-center items-center transition-opacity border-2 border-b-transparent w-4 h-4 rounded-full animate-spin',
    {
      'inset absolute': isLoading,
      'opacity-0': !isLoading,
      'opacity-100': isLoading,
    },
  );

  return (
    <button
      type={type}
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {icon && <span className={spanClasses}>{icon}</span>}
      {label && <span className={spanClasses}>{label}</span>}

      {isLoading && <i className={loadingClasses} />}
    </button>
  );
};
