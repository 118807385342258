import { ReactNode } from 'react';
import { LoginButtonType } from '../components/atoms/LoginButton';
import { IAccessBlock } from '../components/organisms/AccessBlock';
import { ExternalUrls } from '../utils/pageUrls';

export interface IHome {
  accessBlock: IAccessBlock;
  title: string;
  welcome: ReactNode;
  description: ReactNode;
  titleMobile: string;
  welcomeMobile: ReactNode;
  downloadUrl: string;
}

export default function useHome(): IHome {
  return {
    accessBlock: {
      buttons: [
        {
          type: LoginButtonType.FACEBOOK,
          href: '/auth/facebook',
          target: '_parent',
          title: 'Jugar Truco con tu cuenta de Facebook',
          children: (
            <>
              <p className="text-xl font-bold -mb-1">Jugar</p>
              <span>con Facebook</span>
            </>
          ),
        },
        {
          type: LoginButtonType.GOOGLE,
          href: '/auth/google',
          target: '_parent',
          title: 'Jugar Truco con tu cuenta de Google!',
          children: (
            <>
              <p className="text-xl font-bold -mb-1">Jugar</p>
              <span>con Google</span>
            </>
          ),
        },
        {
          type: LoginButtonType.GUEST,
          href: '/jugar-sin-registrarse',
          target: '_parent',
          title: 'Jugar Truco sin registrarse!',
          children: (
            <>
              <p className="text-xl font-bold -mb-1">Jugar</p>
              <span>sin registrarse</span>
            </>
          ),
        },
      ],
      guestLabel: 'Si sos un usuario ya registrado con email, entra ',
      guestLink: {
        href: '/login',
        title: 'Jugar truco con tu cuenta de e-mail',
        children: 'aquí',
      },
    },
    title: '¡Jugar gratis ya!',
    welcome: (
      <>
        <strong>Ganá monedas</strong> en nuestros campeonatos de Truco y jugá
        con <strong>los mejores del Ranking</strong>.
      </>
    ),
    description: (
      <>
        Jugá al <strong>Truco Argentino gratis</strong>, el único con las{' '}
        <strong>reglas oficiales y exactas</strong> de nuestro truco. Podés
        jugar con amigos <strong>de 2, de 4, o de 6</strong>.
      </>
    ),
    titleMobile: 'Descargá la App!',
    welcomeMobile: (
      <>
        Podés jugar al <strong>Truco Argentino online gratis</strong> en tu
        celular desde donde quieras, <strong>con o sin registro</strong>.
      </>
    ),
    downloadUrl: `${ExternalUrls.GOOGLE_PLAY_APP}&utm_source=truco_truco_app_landing`,
  };
}
