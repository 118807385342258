'use client';
import { AccessBlock } from '@/app/truco/components/organisms/AccessBlock';
import { BannerGooglePlay } from '@/app/truco/components/organisms/BannerGooglePlay';
import useHome from '@/app/truco/hooks/useHome';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from '@maestro/catarata/src/components/ui/atoms/Button';

export default function Home() {
  const {
    accessBlock,
    title,
    welcome,
    description,
    titleMobile,
    welcomeMobile,
    downloadUrl,
  } = useHome();
  const router = useRouter();
  const [showMobileAccess, setShowMobileAccess] = useState(false);

  useEffect(() => {
    fetch('/api/server-data', { cache: 'no-store' })
      .then((data) => data.json())
      .then((sd) => {
        if (sd.redirect && sd.where === 'logout') {
          return;
        } else {
          router.push('/loggedCallback');
        }
      });
  }, []);

  return (
    <>
      <section className="flex flex-col min-h-screen items-center justify-center py-2 sm:bg-floating-coins sm:bg-no-repeat sm:bg-[center_top_2rem]">
        <section className="flex flex-col items-center">
          <Image
            src="/img/newdesign/v2/home/floating-header.png"
            alt=""
            width={468}
            height={196}
            aria-hidden="true"
            className="-mb-12 relative z-10"
          />
          <Image
            src="/img/newdesign/v2/home/floating-coins.png"
            alt=""
            width={468}
            height={333}
            aria-hidden="true"
            className="hidden absolute z-10 mt-40 pointer-events-none sm:block"
          />
          <section className="flex flex-col p-10 text-white bg-surface-container-lowest rounded-3xl max-w-[350px]">
            <section className="hidden flex-col gap-5 text-center sm:flex">
              <h1 className="text-4xl font-light">{title}</h1>
              <p className="text-lg">{welcome}</p>
              <AccessBlock {...accessBlock} />
            </section>
            <section className="flex flex-col gap-5 text-center items-center sm:hidden">
              <h1 className="text-3xl font-light mt-2">{titleMobile}</h1>
              <p className="text-md">{welcomeMobile}</p>
              <a href={downloadUrl} className="inline-flex">
                <Image
                  src="/img/newdesign/v2/app/button-google-play.png"
                  alt="Descargá El Gran Truco Argentino desde Google Play"
                  width="145"
                  height="56"
                />
              </a>
              {!showMobileAccess ? (
                <p className="flex gap-2 items-center justify-center text-md w-full">
                  <span>No tenés Android?</span>

                  <Button
                    type={ButtonType.BUTTON}
                    size={ButtonSize.SMALL}
                    variant={ButtonVariant.SECONDARY}
                    label="Entrá acá"
                    onClick={() => setShowMobileAccess(!showMobileAccess)}
                  />
                </p>
              ) : null}

              <div className={`${showMobileAccess ? '' : 'hidden'}`}>
                <AccessBlock {...accessBlock} />
              </div>
              <Image
                src="/img/newdesign/v2/home/home-app-art.png"
                alt=""
                width={373}
                height={391}
                aria-hidden="true"
                className="-mb-10 block"
              />
            </section>
          </section>
        </section>
        <section className="hidden items-center px-4 py-8 gap-5 flex-col md:max-w-2xl md:flex">
          <p className="text-white text-center">{description}</p>
        </section>
      </section>
      <section className="hidden items-center flex-col md:flex">
        <div className="md:max-w-2xl pt-5">
          <BannerGooglePlay />
        </div>
      </section>
    </>
  );
}
