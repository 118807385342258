import Link from 'next/link';
import { ReactNode } from 'react';
import { ILoginButton, LoginButton } from '../atoms/LoginButton';

export interface IAccessBlock {
  buttons: ILoginButton[];
  guestLink: {
    href: string;
    title: string;
    children: ReactNode;
  };
  guestLabel: string;
}

export const AccessBlock = ({
  buttons,
  guestLabel,
  guestLink,
}: IAccessBlock) => {
  return (
    <div className="flex flex-col items-center gap-5 text-left">
      {buttons.map((button, buttonIndex) => {
        return <LoginButton {...button} key={`access-button-${buttonIndex}`} />;
      })}
      <p className="px-4 text-base text-center">
        <span>{guestLabel}</span>
        <Link
          {...guestLink}
          className="text-primary font-bold md:hover:underline"
        />
      </p>
    </div>
  );
};
