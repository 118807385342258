import classNames from 'classnames';
import React, { ReactNode } from 'react';

export enum LoginButtonType {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  GUEST = 'guest',
}

export interface ILoginButton {
  href: string;
  type: LoginButtonType;
  title: string;
  target?: '_parent' | '_self' | '_top' | undefined;
  children: ReactNode;
  reversed?: true | false;
  className?: string;
}

export const LoginButton: React.FunctionComponent<ILoginButton> = ({
  href,
  type,
  children,
  reversed,
  title,
  className,
  target,
}: ILoginButton) => {
  const buttonClasses = classNames(className, {
    'flex-row-reverse': reversed,
    'bg-primary bg-icon-facebook': type === LoginButtonType.FACEBOOK,
    'bg-google bg-icon-google': type === LoginButtonType.GOOGLE,
    'bg-secondary bg-icon-guest': type === LoginButtonType.GUEST,
  });

  return (
    <a
      target={target}
      href={href}
      className={`flex flex-col w-full bg-contain bg-right bg-no-repeat py-0.5 px-2 -gap-2 uppercase text-white rounded-lg md:transition md:hover:brightness-110 ${buttonClasses}`}
      title={title}
    >
      {children}
    </a>
  );
};
